const styles = {
  global: {
    // styles for the `body`
    body: {
      // color: "primary.400",
    },
  },
};

export default styles;
